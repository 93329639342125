function innerPage() {

    
    var urlLike = v1url + "/likes/add";
    var urlFavorite = v1url + "/favorites/add";
    var urlContact = v1url + "/contact/list";
    
    var likeflag;
    var favoriteflag;
    var article_id = _article_id;
    var commentHtml;
    var comment_id;
    var agree;
    var $deletethis;
    var windowWidth = $(window).width();

    var token = "";
    if ($.jStorage.get("localToken")) {
        token = $.jStorage.get("localToken");
    }
    var uid = $.jStorage.get("localUid");
    var name = $.jStorage.get("localName");
    var commentAvatar = '';

    //判断是否为手机
    function IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
            "SymbianOS", "Windows Phone",
            "iPad", "iPod"
        ];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }

    var contactFlag = 1;
    $("#cancel_contact").click(function() {
        $(".swapContact").hide();
        $(".showYellow").css("display", "block");
        contactFlag = 0;
    });

    $(".showYellow").click(function() {
        $(".swapContact").show();
        $(".showYellow").css("display", "none");
        contactFlag = 1;
    })

    if ($(".title").width() >= 800) {
        //console.log("ss");
        $(".title").css("line-height", "30px");
    }

    //求联系banner
    $(window).scroll(function() {
        var h = $(this).scrollTop(); //获得滚动条距top的高度
        //console.log(h);
        var diff = document.getElementById("main-footer").offsetTop - h;
        //console.log(diff);
        if (contactFlag == 1) {
            if (diff <= 660) {
                $(".swapContact").hide();
            } else {
                $(".swapContact").show();
            }
        } else {
            if (diff <= 660) {
                $(".showYellow").hide();
            } else {
                $(".showYellow").show();
            }
        }
    });

    //呈现喜欢颜色
    if (token) {
        $.ajax({
            'url': v1url + "user/article?article_id=" + article_id,
            'type': "GET",
            'headers': {
                "token": token,
                "devicetoken": cookie_PENCILNEWSID,
            },
            'success': function(data) {
                if (data.code != 1000) {
                    return;
                }

                if (data.data.liked) {
                    $(".icon-like").css("color", "#FF7873");
                    likeflag = true;
                }
                if (data.data.userFavorit) {
                    $(".icon-collection").css("color", "#FFF000");
                    favoriteflag = true;
                }
            }
        })
    } else if ($.jStorage.get("likeId" + article_id)) {
        //获取缓存有本文章并且喜欢
        if ($.jStorage.get("likeId" + article_id) == "yes") {
            $(".icon-like").css("color", "#FF7873");
        }
    } else {
        //   $(".icon-like").css("color", "#9b9b9b");
    }
    //判断url
    function IsURL(str_url) {
        var strRegex = '^((https|http|ftp|rtsp|mms)?://)' +
            // '?(([0-9a-z_!~*\'().&=+$%-]+: )?[0-9a-z_!~*\'().&=+$%-]+@)?' //ftp的user@
            // +
            '(([0-9]{1,3}.){3}[0-9]{1,3}' // IP形式的URL- 199.194.52.184
            +
            '|' // 允许IP和DOMAIN（域名）
            +
            '([0-9a-z_!~*\'()-]+.)*' // 域名- www.
            +
            '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' // 二级域名
            +
            '[a-z]{2,6})' // first level domain- .com or .museum
            +
            '(:[0-9]{1,4})?' // 端口- :80
            +
            '((/?)|' // a slash isn't required if there is no file name
            +
            '(/[0-9a-z_!~*\'().;?:@&=+$,%#-]+)+/?)$';

        var re = new RegExp(strRegex);
        //re.test()
        if (re.test(str_url)) {
            return (true);
        } else {
            return (false);
        }
    }

    $.ajax({
        'url': v1url + "articles/detail",
        'type': "GET",
        'data': {
            "article_id": article_id
        },
        'headers': {
            "token": token,
            "devicetoken": cookie_PENCILNEWSID,
        },
        'success': function(data) {
                if(data.code == -1){
                    return;
                }
                if (data.data.article.articleContacts[0].contacted == 1) {
                    $(".contact-entrepreneur").html("已同意");
                    $.jStorage.set("agreed-" + article_id, "agreed");
                } else if (data.data.article.articleContacts[0].contacted == -1) {
                    $(".contact-entrepreneur").html("已拒绝");
                    $.jStorage.set("refused-" + article_id, "refused");
                } else if (data.data.article.articleContacts[0].contacted == 2) {
                    $(".contact-entrepreneur").html("等待对方同意");
                    $.jStorage.set("waitingApproved-" + article_id, "waiting");
                } else {
                    $.jStorage.deleteKey("agreed-" + article_id);
                    $.jStorage.deleteKey("refused-" + article_id);
                    $.jStorage.deleteKey("waitingApproved-" + article_id);
                }
        }
                                     
    });

    getComments();
    //like add 接口
    $("#like").click(function() {
        token = $.jStorage.get("localToken");
        if (!likeflag && token) {
            $.ajax({
                'method': 'POST',
                'url': urlLike,
                'headers': {
                    "token": token,
                    "devicetoken": cookie_PENCILNEWSID,
                },
                'data': {
                    "article_id": article_id
                },
                'success': function(data) {
                    if (!checkInterfaceCode(data)) {
                        console.log("190")
                        return;
                    }
                    $(".icon-like").css("color", "#FF7873");
                    likeflag = true;
                    $("#likes_count").html(parseInt($("#likes_count").text()) + 1);
                }
            });
        } else if (token == null) {
            var like_articleId = "likeId" + article_id;
            if ($.jStorage.get(like_articleId)) {
                if ($.jStorage.get(like_articleId) == "yes") {
                    //有缓存但是点过赞，不能点赞，点击无效（或者取消点赞?）
                    return;
                } else {
                    //有缓存，没点过赞，可以点赞，点赞
                    $.ajax({
                        'method': 'POST',
                        'url': urlLike,
                        'headers':{
                            "devicetoken": cookie_PENCILNEWSID,
                        },
                        'data': {
                            "article_id": article_id
                        },
                        'success': function(data) {
                            if (!checkInterfaceCode(data)) {
                                console.log("217");
                                return;
                            }
                            $.jStorage.set(like_articleId, "yes");
                            $(".icon-like").css("color", "#FF7873");
                            $("#likes_count").html(parseInt($("#likes_count").text()) + 1);
                        }
                    });
                }
            } else {
                //没有本地缓存，可以点赞
                $.ajax({
                    'method': 'POST',
                    'url': urlLike,
                    'headers':{
                            "devicetoken": cookie_PENCILNEWSID,
                    },
                    'data': {
                        "article_id": article_id
                    },
                    'success': function(data) {
                        if (!checkInterfaceCode(data)) {
                            console.log("238");
                            return;
                        }
                        $.jStorage.set(like_articleId, "yes");
                        $(".icon-like").css("color", "#FF7873");
                        $("#likes_count").html(parseInt($("#likes_count").text()) + 1);
                    }
                });
            }

        }

    });
    //like delete 接口
    $("#like").click(function() {
        token = $.jStorage.get("localToken");
        if (likeflag) {
            $.ajax({
                'url': v1url + "/likes/remove?" + jQuery.param({
                    "article_id": article_id,
                }),
                'type': "DELETE",
                'headers': {
                    "token": token,
                    "devicetoken": cookie_PENCILNEWSID,
                },
                'success': function(data) {
                    if (!checkInterfaceCode(data)) {
                        console.log("267");
                        return;
                    }
                    $(".icon-like").css("color", "#9b9b9b");
                    likeflag = false;
                    $("#likes_count").html(parseInt($("#likes_count").text()) - 1);
                }
            })
        }
    });

    //favorite add 接口
    $("#collection").click(function() {
            token = $.jStorage.get("localToken");
            if (!favoriteflag && token) {
                $.ajax({
                    'method': 'POST',
                    'url': urlFavorite,
                    'headers': {
                        "token": token,
                        "devicetoken": cookie_PENCILNEWSID,
                    },
                    'data': {
                        "article_id": article_id
                    },
                    'success': function(data) {
                        if (!checkInterfaceCode(data)) {
                            console.log("293");
                            return;
                        }
                        $(".icon-collection").css("color", "#FFF000");
                        favoriteflag = true;
                        $("#favorites_count").html(parseInt($("#favorites_count").text()) + 1);
                        //alert(parseInt($("#favorites_count").text()));
                    }
                })
            }
        })
        //Favorite delete 接口
    $("#collection").click(function() {
        token = $.jStorage.get("localToken");
        if (favoriteflag) {
            $.ajax({
                'url': v1url + "/favorites/remove?" + jQuery.param({
                    "article_id": article_id,
                }),
                'type': "DELETE",
                'headers': {
                    "token": token,
                    "devicetoken": cookie_PENCILNEWSID,
                },
                'success': function(data) {
                    if (!checkInterfaceCode(data)) {
                        console.log("320");
                        return;
                    }
                    $(".icon-collection").css("color", "#9b9b9b");
                    favoriteflag = false;
                    $("#favorites_count").html(parseInt($("#favorites_count").text()) - 1);
                }
            })
        }
    })


    //get comments 接口
    function getComments() {
        token = $.jStorage.get("localToken");
        if (token) {
            $('#reply_login').css("display", "none");
            $.ajax({
                'url': v1url + "articles/comments",
                'type': "GET",
                'data': {
                    "article_id": article_id,
                    "page_size": "",
                    "page": "",
                    "time_start": "",
                },
                'headers': {
                    "token": token,
                    "devicetoken": cookie_PENCILNEWSID,
                },
                'success': function(data) {
                    //showWarning(data.data.comments[data.data.comments.length-2].reply_user);
                    //showWarning(typeof data);
                    if (data.code == -1) {
                        console.log("399");
                        return;
                    }
                    var comments_length = data.data.comments.length;
                    uid = $.jStorage.get("localUid");
                    name = $.jStorage.get("localName");

                    //show comments
                    for (var i = 0; i < comments_length; i++) {

                        if (data.data.comments[i].user.profile.avatar == null) {
                            commentAvatar = '/imgs/defaulthead.png';
                        } else {
                            commentAvatar = 'http://cdn.pencilnews.cn/' + data.data.comments[i].user.profile.avatar + "?imageView2/2/w/500/h/500/q/75";
                        }
                        if (uid == data.data.comments[i].user.uid) {
                            if (data.data.comments[i].reply_user != null) {

                                commentHtml = '<div class="reply_area"><div class="row"><div class="pull-left col-sm-1 reply_img"><div><img src="' + commentAvatar + '"></div></div><div class="col-sm-11"><div class="reply_title"><div class="pull-left"><p><strong>' + data.data.comments[i].user.profile.name + '</strong></p><i>回复</i><p><strong>' + data.data.comments[i].reply_user.profile.name + '</strong></p><span class = "create_time">' + data.data.comments[i].create_at + '</span></div><div class="pull-right"><a style="color:#9b9b9b;" class="agreeLink" id= "' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "count' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div></div><div class="col-xs-11 reply_content" id="comment-' + data.data.comments[i].comment_id + '" name="comment-' + data.data.comments[i].comment_id + '"><p>' + data.data.comments[i].content + '</p></div><div class="pull-left date_reply"><span class = "create_time">' + data.data.comments[i].create_at + '</span><a style="color:#9b9b9b;" class="agreeLink" id= "ss' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "countss' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div><div class="pull-right deleteLink"><a class="delete_link">删除</a></div></div></div><div class="reply_reply"><textarea autofocus class="reply_place"></textarea><div class="reply_buttons"><button class= "cancel_btn">取消</button><button class="reply_btn">评论</button><span class="clearfix"></span></div></div></div>';
                                $('#comment_place').append(commentHtml);
                            } else {
                                commentHtml = '<div class="reply_area"><div class="row"><div class="pull-left col-sm-1 reply_img"><div><img src="' + commentAvatar + '"></div></div><div class="col-sm-11"><div class="reply_title"><div class="pull-left"><p><strong>' + data.data.comments[i].user.profile.name + '</strong></p><span class = "create_time">' + data.data.comments[i].create_at + '</span></div><div class="pull-right"><a style="color:#9b9b9b;" class="agreeLink" id= "' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "count' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div></div><div class="col-xs-11 reply_content" id="comment-' + data.data.comments[i].comment_id + '" name="comment-' + data.data.comments[i].comment_id + '"><p>' + data.data.comments[i].content + '</p></div><div class="pull-left date_reply"><span class = "create_time">' + data.data.comments[i].create_at + '</span><a style="color:#9b9b9b;" class="agreeLink" id= "ss' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "countss' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div><div class="pull-right deleteLink"><a class="delete_link">删除</a></div></div></div><div class="reply_reply"><textarea autofocus class="reply_place"></textarea><div class="reply_buttons"><button class= "cancel_btn">取消</button><button class="reply_btn">评论</button><span class="clearfix"></span></div></div></div>';

                                $('#comment_place').append(commentHtml);
                            }
                        } else {
                            if (data.data.comments[i].reply_user != null) {

                                commentHtml = '<div class="reply_area"><div class="row"><div class="pull-left col-sm-1 reply_img"><div><img src="' + commentAvatar + '"></div></div><div class="col-sm-11"><div class="reply_title"><div class="pull-left"><p><strong>' + data.data.comments[i].user.profile.name + '</strong></p><i>回复</i><p><strong>' + data.data.comments[i].reply_user.profile.name + '</strong></p><span class = "create_time">' + data.data.comments[i].create_at + '</span></div><div class="pull-right"><a style="color:#9b9b9b;" class="agreeLink" id= "' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "count' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div></div><div class="col-xs-11 reply_content" id="comment-' + data.data.comments[i].comment_id + '" name="comment-' + data.data.comments[i].comment_id + '"><p>' + data.data.comments[i].content + '</p></div><div class="pull-left date_reply"><span class = "create_time">' + data.data.comments[i].create_at + '</span><a style="color:#9b9b9b;" class="agreeLink" id= "ss' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "countss' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div><div class="pull-right deleteLink"></div></div></div><div class="reply_reply"><textarea autofocus class="reply_place"></textarea><div class="reply_buttons"><button class= "cancel_btn">取消</button><button class="reply_btn">评论</button><span class="clearfix"></span></div></div></div>';
                                $('#comment_place').append(commentHtml);
                            } else {
                                commentHtml = '<div class="reply_area"><div class="row"><div class="pull-left col-sm-1 reply_img"><div><img src="' + commentAvatar + '"></div></div><div class="col-sm-11"><div class="reply_title"><div class="pull-left"><p><strong>' + data.data.comments[i].user.profile.name + '</strong></p><span class = "create_time">' + data.data.comments[i].create_at + '</span></div><div class="pull-right"><a style="color:#9b9b9b;" class="agreeLink" id= "' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "count' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div></div><div class="col-xs-11 reply_content" id="comment-' + data.data.comments[i].comment_id + '" name="comment-' + data.data.comments[i].comment_id + '"><p>' + data.data.comments[i].content + '</p></div><div class="pull-left date_reply"><span class = "create_time">' + data.data.comments[i].create_at + '</span><a style="color:#9b9b9b;" class="agreeLink" id= "ss' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "countss' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div><div class="pull-right deleteLink"></div></div></div><div class="reply_reply"><textarea autofocus class="reply_place"></textarea><div class="reply_buttons"><button class= "cancel_btn">取消</button><button class="reply_btn">评论</button><span class="clearfix"></span></div></div></div>';

                                $('#comment_place').append(commentHtml);
                            }

                        }

                        if (data.data.comments[i].approved == true) {

                            //console.log(data.data.comments[i].comment_id);
                            $("#" + data.data.comments[i].comment_id).css("color", "#ffac00");
                            $("#ss" + data.data.comments[i].comment_id).css("color", "#ffac00");
                            agree = true;
                        }

                    }

                    if (windowWidth > 767 && IsPC()) {
                        //console.log("s");
                        $(".reply_img").css({
                            "padding-right": "0px"
                        });
                    }

                    $(".replyLink").click(function() {
                        $(this).parent().parent().parent().parent().parent().children('.reply_reply').css("display", "block");
                    });

                    if (!IsPC()) {
                        $(".replyLink").click(function() {
                            $(this).parent().parent().parent().parent().children('.reply_reply').css("display", "block");
                        });
                    }

                    $(".cancel_btn").click(function() {
                        $(this).parent().parent().css("display", "none");
                    });

                    //回复评论
                    $('.reply_btn').click(function() {

                        token = $.jStorage.get("localToken");
                        comments = $(this).parent().parent().children('textarea').val();

                        len = comments.length;
                        var comflag = true;
                        for (var i = 0; i < len; i++) {
                            for (var k = i + 1; k < len + 1; k++) {
                                if (IsURL(comments.substring(i, k))) {
                                    //alert(comments.substring(i, k));
                                    comflag = false;
                                    showWarning("请您不要在回复评论中加入网址，谢谢！");
                                    break;
                                }
                            }
                        }

                        if (comments <= 0) {
                            showWarning("评论不能为空，请您填写内容！");
                        } else if (comflag) {
                            var $this = $(this).parent().parent().parent().children('.row').children('.col-sm-11').children('.reply_title').children('.pull-left').children("span").text();

                            var comments_length = data.data.comments.length;
                            for (var i = 0; i < comments_length; i++) {
                                if ($this == data.data.comments[i].create_at) {
                                    comment_id = data.data.comments[i].comment_id;
                                    $.ajax({
                                        'url': v1url + "comments/add",
                                        'type': "POST",
                                        'headers': {
                                            "token": token,
                                            "devicetoken": cookie_PENCILNEWSID,
                                        },
                                        'data': {
                                            "content": comments,
                                            "article_id": article_id,
                                            "comment_id": comment_id
                                        },
                                        'success': function(data) {
                                            if (!checkInterfaceCode(data)) {
                                                console.log("510");
                                                return;
                                            }

                                            location.reload();

                                        }
                                    })
                                }
                            }
                        }
                    });

                    $(".delete_link").click(function() {
                        $deletethis = $(this).parent().parent().children('.reply_title').children('.pull-left').children("span").text();
                        $('.cover').css("display", "block");
                        $('.delete_panel').css("display", "block");
                    });

                    $(".no_btn").click(function() {

                        $('.cover').css("display", "none");
                        $('.delete_panel').css("display", "none");
                    });

                    //删除评论
                    $(".yes_btn").click(function() {

                        token = $.jStorage.get("localToken");

                        var comments_length = data.data.comments.length;
                        for (var i = 0; i < comments_length; i++) {
                            if ($deletethis == data.data.comments[i].create_at) {
                                comment_id = data.data.comments[i].comment_id;
                                $.ajax({
                                    'url': v1url + "comments/remove?" + jQuery.param({
                                        "comment_id": comment_id,
                                    }),
                                    'type': "DELETE",
                                    'headers': {
                                        "token": token,
                                        "devicetoken": cookie_PENCILNEWSID,
                                    },
                                    'success': function(data) {
                                        if (!checkInterfaceCode(data)) {
                                            console.log("556");
                                            return;
                                        }
                                        location.reload();
                                    }
                                })
                            }
                        }
                    });

                    //赞同 / 取消赞同评论
                    if (IsPC()) {
                        $(".agreeLink").click(function() {

                            token = $.jStorage.get("localToken");
                            var $this = $(this).parent().parent().children('.pull-left').children("span").text();
                            var comments_length = data.data.comments.length;
                            for (var i = 0; i < comments_length; i++) {
                                if ($this == data.data.comments[i].create_at) {
                                    comment_id = data.data.comments[i].comment_id;

                                    $.ajax({
                                        'url': v1url + "comments/approve",
                                        'type': "POST",
                                        'headers': {
                                            "token": token,
                                            "devicetoken": cookie_PENCILNEWSID,
                                        },
                                        'data': {
                                            "comment_id": comment_id
                                        },
                                        'success': function(data) {
                                            if (!checkInterfaceCode(data)) {
                                                console.log("588");
                                                return;
                                            }
                                            var state = data.data.state;
                                            if (state == true) {
                                                agree = true;
                                                $("#" + comment_id).css("color", "#ffac00");
                                                $("#count" + comment_id).html(parseInt($("#count" + comment_id).text()) + 1);
                                            } else {
                                                agree = false;
                                                $("#" + comment_id).css("color", "#9b9b9b");
                                                $("#count" + comment_id).html(parseInt($("#count" + comment_id).text()) - 1);
                                            }
                                            //location.reload();
                                        }
                                    })
                                }
                            }

                        });
                    }
                    if (!IsPC()) {
                        $(".agreeLink").click(function() {

                            token = $.jStorage.get("localToken");
                            var $this = $(this).parent().children("span").text();
                            //alert($this);
                            var comments_length = data.data.comments.length;
                            //alert(comments_length);
                            for (var i = 0; i < comments_length; i++) {
                                if ($this == data.data.comments[i].create_at) {
                                    comment_id = data.data.comments[i].comment_id;

                                    $.ajax({
                                        'url': v1url + "comments/approve",
                                        'type': "POST",
                                        'headers': {
                                            "token": token,
                                            "devicetoken": cookie_PENCILNEWSID,
                                        },
                                        'data': {
                                            "comment_id": comment_id
                                        },
                                        'success': function(data) {
                                            if (!checkInterfaceCode(data)) {
                                                console.log("634");
                                                return;
                                            }
                                            var state = data.data.state;
                                            if (state == true) {
                                                agree = true;
                                                $("#ss" + comment_id).css("color", "#ffac00");
                                                $("#countss" + comment_id).html(parseInt($("#countss" + comment_id).text()) + 1);

                                            } else {
                                                agree = false;
                                                $("#ss" + comment_id).css("color", "#9b9b9b");
                                                $("#countss" + comment_id).html(parseInt($("#countss" + comment_id).text()) - 1);
                                            }
                                            //location.reload();
                                        }
                                    })
                                }
                            }

                        });
                    }

                    //滚动到评论
                    var commentid = window.location.hash;
                    if (commentid) {
                        //console.log(commentid)
                        var scroll_offset = $(commentid).offset();
                        //console.log(scroll_offset)
                        $("body,html").animate({
                            scrollTop: scroll_offset.top
                        }, 0);
                    }
                }
            });
        } else {
            $('#commentsArea').attr("disabled", "disabled");
            $.ajax({
                'url': v1url + "articles/comments",
                'type': "GET",
                'data': {
                    "article_id": article_id,
                    "page_size": "",
                    "page": "",
                    "time_start": "",
                },
                'headers': {
                    "devicetoken": cookie_PENCILNEWSID,
                },
                'success': function(data) {
                    if (!checkInterfaceCode(data)) {
                        console.log("684");
                        return;
                    }
                    var comments_length = data.data.comments.length;
                    uid = $.jStorage.get("localUid");
                    name = $.jStorage.get("localName");

                    for (var i = 0; i < comments_length; i++) {
                        //console.log(data.data.comments[i].user.name);
                        if (data.data.comments[i].user.profile.avatar == null) {
                            commentAvatar = '/imgs/defaulthead.png';
                        } else {
                            commentAvatar = 'http://cdn.pencilnews.cn/' + data.data.comments[i].user.profile.avatar + "?imageView2/2/w/500/h/500/q/75";
                        }

                        if (uid == data.data.comments[i].user.uid) {
                            //showWarning(uid);
                            if (data.data.comments[i].reply_user != null) {

                                commentHtml = '<div class="reply_area"><div class="row"><div class="pull-left col-sm-1 reply_img"><div><img src="' + commentAvatar + '"></div></div><div class="col-sm-11"><div class="reply_title"><div class="pull-left"><p><strong>' + data.data.comments[i].user.profile.name + '</strong></p><i>回复</i><p><strong>' + data.data.comments[i].reply_user.profile.name + '</strong></p><span class = "create_time">' + data.data.comments[i].create_at + '</span></div><div class="pull-right"><a style="color:#9b9b9b;" class="agreeLink" id= "' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "count' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div></div><div class="col-xs-11 reply_content" id="comment-' + data.data.comments[i].comment_id + '" name="comment-' + data.data.comments[i].comment_id + '"><p>' + data.data.comments[i].content + '</p></div><div class="pull-left date_reply"><span class = "create_time">' + data.data.comments[i].create_at + '</span><a style="color:#9b9b9b;" class="agreeLink" id= "ss' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "countss' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div><div class="pull-right deleteLink"><a class="delete_link">删除</a></div></div></div><div class="reply_reply"><textarea autofocus class="reply_place"></textarea><div class="reply_buttons"><button class= "cancel_btn">取消</button><button class="reply_btn">评论</button><span class="clearfix"></span></div></div></div>';

                                $('#comment_place').append(commentHtml);

                            } else {

                                commentHtml = '<div class="reply_area"><div class="row"><div class="pull-left col-sm-1 reply_img"><div><img src="' + commentAvatar + '"></div></div><div class="col-sm-11"><div class="reply_title"><div class="pull-left"><p><strong>' + data.data.comments[i].user.profile.name + '</strong></p><span class = "create_time">' + data.data.comments[i].create_at + '</span></div><div class="pull-right"><a style="color:#9b9b9b;" class="agreeLink" id= "' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "count' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div></div><div class="col-xs-11 reply_content" id="comment-' + data.data.comments[i].comment_id + '" name="comment-' + data.data.comments[i].comment_id + '"><p>' + data.data.comments[i].content + '</p></div><div class="pull-left date_reply"><span class = "create_time">' + data.data.comments[i].create_at + '</span><a style="color:#9b9b9b;" class="agreeLink" id= "ss' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "countss' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div><div class="pull-right deleteLink"><a class="delete_link">删除</a></div></div></div><div class="reply_reply"><textarea autofocus class="reply_place"></textarea><div class="reply_buttons"><button class= "cancel_btn">取消</button><button class="reply_btn">评论</button><span class="clearfix"></span></div></div></div>';

                                $('#comment_place').append(commentHtml);

                            }
                        } else {
                            if (data.data.comments[i].reply_user != null) {

                                commentHtml = '<div class="reply_area"><div class="row"><div class="pull-left col-sm-1 reply_img"><div><img src="' + commentAvatar + '"></div></div><div class="col-sm-11"><div class="reply_title"><div class="pull-left"><p><strong>' + data.data.comments[i].user.profile.name + '</strong></p><i>回复</i><p><strong>' + data.data.comments[i].reply_user.profile.name + '</strong></p><span class = "create_time">' + data.data.comments[i].create_at + '</span></div><div class="pull-right"><a style="color:#9b9b9b;" class="agreeLink" id= "' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "count' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div></div><div class="col-xs-11 reply_content" id="comment-' + data.data.comments[i].comment_id + '" name="comment-' + data.data.comments[i].comment_id + '"><p>' + data.data.comments[i].content + '</p></div><div class="pull-left date_reply"><span class = "create_time">' + data.data.comments[i].create_at + '</span><a style="color:#9b9b9b;" class="agreeLink" id= "ss' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "countss' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div><div class="pull-right deleteLink"></div></div></div><div class="reply_reply"><textarea autofocus class="reply_place"></textarea><div class="reply_buttons"><button class= "cancel_btn">取消</button><button class="reply_btn">评论</button><span class="clearfix"></span></div></div></div>';

                                $('#comment_place').append(commentHtml);

                            } else {

                                commentHtml = '<div class="reply_area"><div class="row"><div class="pull-left col-sm-1 reply_img"><div><img src="' + commentAvatar + '"></div></div><div class="col-sm-11"><div class="reply_title"><div class="pull-left"><p><strong>' + data.data.comments[i].user.profile.name + '</strong></p><span class = "create_time">' + data.data.comments[i].create_at + '</span></div><div class="pull-right"><a style="color:#9b9b9b;" class="agreeLink" id= "' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "count' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div></div><div class="col-xs-11 reply_content" id="comment-' + data.data.comments[i].comment_id + '" name="comment-' + data.data.comments[i].comment_id + '"><p>' + data.data.comments[i].content + '</p></div><div class="pull-left date_reply"><span class = "create_time">' + data.data.comments[i].create_at + '</span><a style="color:#9b9b9b;" class="agreeLink" id= "ss' + data.data.comments[i].comment_id + '"><span class="icon-agreewith" style="margin-right:5px;""></span><span id= "countss' + data.data.comments[i].comment_id + '">' + data.data.comments[i].approval_count + '</span></a><a style="color:#9b9b9b;" class="replyLink"><span class="icon-report"></span>回复</a></div><div class="pull-right deleteLink"></div></div></div><div class="reply_reply"><textarea autofocus class="reply_place"></textarea><div class="reply_buttons"><button class= "cancel_btn">取消</button><button class="reply_btn">评论</button><span class="clearfix"></span></div></div></div>';

                                $('#comment_place').append(commentHtml);


                                $(".agreeLink").each(function() {
                                    var localCommentId = $(this).attr("id");

                                    if ($.jStorage.get("likeId" + localCommentId)) {
                                        var likedCommentId = $.jStorage.get("likeId" + localCommentId);
                                        if (likedCommentId == localCommentId) {
                                            $("#" + likedCommentId).css("color", "#ffac00");
                                            $("#ss" + likedCommentId).css("color", "#ffac00");
                                        }
                                    }

                                });

                            }

                        }
                    }

                    $(".replyLink").click(function() {

                        showWarning('请您登录后回复！');
                        //$('.agreeLink').hide();
                        $('.replyLink').hide();

                    });

                    //赞同 / 取消赞同评论
                    $(".agreeLink").click(function() {


                        //                        $('.agreeLink').hide();
                        //                        $('.replyLink').hide();
                        //                        showWarning('请您登录后点赞！');
                        var mobileCommentId = $(this).attr("id");
                        tempCommentId = mobileCommentId.replace("ss", "");
                        var like_commentId = "likeId" + tempCommentId;


                        if ($.jStorage.get("likeId" + tempCommentId)) {
                            if ($.jStorage.get("likeId" + tempCommentId) == tempCommentId) {
                                //有缓存但是点过赞，不能点赞，点击无效（或者取消点赞?）
                                return;
                            } else {
                                //有缓存，没点过赞，可以点赞，点赞
                                $.ajax({
                                    'method': 'POST',
                                    'url': v1url + "comments/approve",
                                    'headers': {
                                        "devicetoken": cookie_PENCILNEWSID,
                                    },
                                    'data': {
                                        "comment_id": tempCommentId
                                    },
                                    'success': function(data) {
                                        if (!checkInterfaceCode(data)) {
                                            console.log("783");
                                            return;
                                        }
                                        if (IsPC()) {
                                            //pc
                                            $.jStorage.set(like_commentId, tempCommentId);
                                            $("#" + tempCommentId).css("color", "#ffac00");
                                            $("#count" + tempCommentId).html(parseInt($("#count" + tempCommentId).text()) + 1);
                                        } else {
                                            //not pc
                                            $.jStorage.set(like_commentId, tempCommentId);
                                            $("#ss" + tempCommentId).css("color", "#ffac00");
                                            $("#countss" + tempCommentId).html(parseInt($("#countss" + tempCommentId).text()) + 1);
                                        }

                                    }
                                });
                            }
                        } else {
                            //没有本地缓存，可以点赞
                            $.ajax({
                                'method': 'POST',
                                'url': v1url + "comments/approve",
                                'headers':{
                                    "devicetoken": cookie_PENCILNEWSID,
                                },
                                'data': {
                                    "comment_id": tempCommentId
                                },
                                'success': function(data) {
                                    if (!checkInterfaceCode(data)) {
                                        return;
                                    }
                                    if (IsPC()) {
                                        //pc
                                        $.jStorage.set(like_commentId, tempCommentId);
                                        $("#" + tempCommentId).css("color", "#ffac00");
                                        $("#count" + tempCommentId).html(parseInt($("#count" + tempCommentId).text()) + 1);
                                    } else {
                                        //not pc
                                        $.jStorage.set(like_commentId, tempCommentId);
                                        $("#ss" + tempCommentId).css("color", "#ffac00");
                                        $("#countss" + tempCommentId).html(parseInt($("#countss" + tempCommentId).text()) + 1);
                                    }

                                }
                            });
                        }



                    });

                    //滚动到评论
                    var commentid = window.location.hash;
                    if (commentid) {
                        //console.log(commentid)
                        var scroll_offset = $(commentid).offset();
                        //console.log(scroll_offset)
                        $("body,html").animate({
                            scrollTop: scroll_offset.top
                        }, 0);
                    }
                }
            });
        }
    }

    //

    //

    //add comments 接口
    $("#btn_comments").click(function() {

        token = $.jStorage.get("localToken");
        //username = $.jStorage.get("localUsername");
        comments = $("#commentsArea").val();
        len = comments.length;
        var comflag = true;
        for (var i = 0; i < len; i++) {
            for (var k = i + 1; k < len + 1; k++) {
                if (IsURL(comments.substring(i, k))) {
                    //alert(comments.substring(i, k));
                    comflag = false;
                    showWarning("请您不要在评论中加入网址，谢谢！");
                    break;
                }
            }
        }
        if (token == null) {
            showWarning('请您登录后评论！');
        } else if (comments <= 0 && token) {
            showWarning("评论不能为空，请您填写内容！");
        } else if (comflag) {

            $.ajax({
                'url': v1url + "comments/add",
                'type': "POST",
                'headers': {
                    "token": token,
                    "devicetoken": cookie_PENCILNEWSID,
                },
                'data': {
                    "content": comments,
                    "article_id": article_id
                },
                'success': function(data) {
                    if (!checkInterfaceCode(data)) {
                        return;
                    }

                    location.reload();
                    getComments();
                    location.reload();

                }
            })
        }
    });


    (function() {
        $("#mobile_filter_menu_con").hide();
    })();

    //联系记者
    $(".contact_journalist").click(function(e) {
        if ($.jStorage.get("localToken")) {
            $("#qrcode").fadeIn();
            $("#innerpage_qrcode_mask").fadeIn();
            $("body").addClass("modal-open");
        } else {
            if (IsPC()) {
                showWarning("登录后才能联系TA哦。");
                showPCLoginForm();
            } else {
                var tempUrl = window.location.href;
                $.jStorage.set("localLastUrl", tempUrl);
                showWarning("登录后才能联系TA哦。");
                setTimeout(function() {
                    window.location = "/login_phone";
                }, 2000);

            }
        }

    });
    
    $("#innerpage_qrcode_mask").click(function(){
        $("#qrcode").fadeOut();
        $("#innerpage_qrcode_mask").fadeOut();
        $("body").removeClass("modal-open");
    });

    if ($(".swapContact").is(":visible")) {
        $(".innerPageAdd").show();
    }


}

//点击取消
$("#valid_btn_cancel").click(function() {
    $("#valid_prompt_mask").hide();
    $("body").removeClass("modal-open");
    $("#valid_prompt_container").hide();
});

$("#close_valid_prompt").click(function() {
    $("#valid_prompt_mask").hide();
    $("body").removeClass("modal-open");
    $("#valid_prompt_container").hide();
});

$("#valid_alert_box_cancel,#valid_alert_box_close").click(function() {
    $("#valid_prompt_mask").hide();
    $("body").removeClass("modal-open");
    $("#valid_alert_box").hide();
});

//点击去认证
$("#valid_alert_box_submit").click(function(){
    if(IsPC()){
        location.href = "/usercenter?cate=my-certification";
    }else{
        location.href = "/m_certification";
    }
});

//点击提交
var valid_btn_clicked = false;
$("#valid_btn_submit").click(function() {
    var contactUid = "";
    var token = $.jStorage.get("localToken");
    var articleId = _article_id;
    if (_contactUid) {
        contactUid = _contactUid;
    }
    console.log("contactUid" + contactUid);
    var reason = $("#valid_request_info_text").val();
    //1.验证信息是否填写完整
    if (reason == "") {
        showWarning("理由不能为空，请重新填写！");
        return;
    }
    if (reason.length < 20) {
        showWarning("理由至少20字以上！");
        return;
    }
    if (reason.length > 200) {
        showWarning("理由不能超过200字！");
        return;
    }
    //2.验证token是否过期，过期需要从新登录

    //3.可以提交
    if (valid_btn_clicked) {

        $("#valid_prompt_mask").hide();
        $("body").removeClass("modal-open");
        $("#valid_prompt_container").hide();
        valid_btn_clicked = false;
        return;
    }
    valid_btn_clicked = true;
    sendContactRequest(v1url, token, articleId, contactUid, reason, function(data) {
        
        var contactLastTime = data.data.contact_last_times;
        contactLastTime = contactLastTime.replace("\\r\\n","");
        showSuccess("您的请求信息已发送，请等待TA的反馈。" + contactLastTime);
        console.log("修改前的：" + data.data.contact_last_times);
//        
//        contactLastTime = contactLastTime.replace("\r\n","");
//        console.log("第一种方式：" + contactLastTime);
//        
//        console.log("修改后的："+contactLastTime);
        $("#valid_prompt_mask").hide();
        $("body").removeClass("modal-open");
        $("#valid_prompt_container").hide();
        valid_btn_clicked = false;
        //    $(".contact-entrepreneur").unbind("click");
        //    $(".contact-entrepreneur").css("background", "#c2c2c2");
        $(".contact-entrepreneur").html("等待对方同意");
        $.jStorage.set("waitingApproved-" + articleId, "waiting");
    });
    //4.提交成功后，弹窗消失，显示成功信息
});

//多选框点击事件


/**************/

/* 联系ta相关function */
//判断是否认证用户
function isValidUser(url, token, fun) {
    url = url + "cert";
    $.get({
        url: url,
        type: "GET",
        headers: {
            "devicetoken": cookie_PENCILNEWSID,
            token: token
        },
        success: function(data) {
            if ((typeof data) == "string") {
                data = $.parseJSON(data);
            }

            if (data.data.cert == null) {
                $("#valid_prompt_mask").show();
                $("body").addClass("modal-open");
                $("#valid_alert_box").show();
            } else if (data.data.cert.state == 1) {
                var contactAvatorUrl = $.jStorage.get("tempImgAvatarUrl");
                if (data.data.cert.user.avatar != null) {
                    contactAvatorUrl = "https://cdn.pencilnews.cn/" + data.data.cert.user.avatar;
                }
                var validLogoUrl = "/imgs/cert_" + data.data.cert.cert_type + ".png";
                fun(data);
            } else if (data.data.cert.state == 0) {
                showWarning("您的认证还在审核中，请等待通过后联系TA");
            } else if (data.data.cert.state == -1) {
                showWarning("您的认证没有通过，不能联系TA");
            }
        }
    });
}


//
$("#valid_request_info_text").keyup(function() {
    var typedWords = $(this).val();
    $("#typed_word").html(typedWords.length);
    if (typedWords.length < 20 || typedWords.length > 200) {
        $("#typed_word").css("color", "red");
    } else {
        $("#typed_word").css("color", "#999999");
    }
    if (typedWords != "") {
        $(this).attr("placeholder", "");
    } else {
        $(this).attr("placeholder", "来者何人，所为何事？详细说明来意，创业者更容易通过哦～");
    }

});

//发送联系ta请求
function sendContactRequest(url, token, articleId, contactUid, reason, fun) {
    url = url + "contact";
    $.post({
        url: url,
        type: "POST",
        headers: {
            "devicetoken": cookie_PENCILNEWSID,
            token: token
        },
        data: {
            "article_id": articleId,
            "contact_uid": contactUid,
            "reason": reason
        },
        success: function(data) {
            if ((typeof data) == "string") {
                data = $.parseJSON(data);
            }
            //            if(checkInterfaceCode(data)){
            //                if(data.code == 1010){
            //                    showWarning("联系人不存在!");
            //                }else{
            //                    fun();
            //                }
            //            }

            if (data.code != 1000) {
                showWarning(data.message);
            } else {
                fun(data);
            }
        }
    });
}
/*********************/
/*********/

//复制到剪切板
//$("#copy_email").click(function(){
//    copyContent($("#board_email"));
//    alert("复制到剪切板成功");
//});

var clipboard = new Clipboard('#copy_email');
//优雅降级:safari 版本号>=10,提示复制成功;否则提示需在文字选中后，手动选择“拷贝”进行复制
clipboard.on('success', function(e) {
    alert('复制成功!')
    e.clearSelection();
});
clipboard.on('error', function(e) {
    alert('很抱歉，暂时不支持当前浏览器版本，请手动复制')
});

var clipboard2 = new Clipboard('#copy_wechat');
//优雅降级:safari 版本号>=10,提示复制成功;否则提示需在文字选中后，手动选择“拷贝”进行复制
clipboard2.on('success', function(e) {
    alert('复制成功!')
    e.clearSelection();
});
clipboard2.on('error', function(e) {
    alert('很抱歉，暂时不支持当前浏览器版本，请手动复制')
});

//$("#copy_wechat").click(function(){
//    copyContent($("#board_wechat"));
//    alert("复制到剪切板成功");
//});

//关闭联系方式的弹框
$("#close_contact_board").click(function(){
    $(".contact_board_container").hide();
    $("#valid_prompt_mask").hide();
    $("body").removeClass("modal-open");
});

//判断对方是否开启了验证才能交换联系方式,目前方法为空，布尔值，默认为false，自动同意为true;
function isAutoAgree(){
    //现在改成全不开启验证 2017.8.23 Lucy
    return true
    if(_auto_accept_contact){
        if(_auto_accept_contact == 0){
            console.log(0);
            return false;
        }
        if(_auto_accept_contact == 1){
            console.log(1);
            return true;
        }
    }else{
        console.log("没这个字段");
        return false;
    }    
}

//
//$(".board_usercenter span").click(function(){
//    if(IsPC()){
//        location.href = "/usercenter?cate=my-contact&state=contact-agreed";
//    }else{
//        location.href = "/m_contactlist";
//    }
//});
$("#valid_btn_submit").click(function() {
    var contactUid = "";
    var token = $.jStorage.get("localToken");
    var articleId = _article_id;
    if (_contactUid) {
        contactUid = _contactUid;
    }
    console.log("contactUid" + contactUid);
    var reason = $("#valid_request_info_text").val();
    //1.验证信息是否填写完整
    if (reason == "") {
        showWarning("理由不能为空，请重新填写！");
        return;
    }
    if (reason.length < 20) {
        showWarning("理由至少20字以上！");
        return;
    }
    if (reason.length > 200) {
        showWarning("理由不能超过200字！");
        return;
    }
    //2.验证token是否过期，过期需要从新登录

    //3.可以提交
    if (valid_btn_clicked) {

        $("#valid_prompt_mask").hide();
        $("body").removeClass("modal-open");
        $("#valid_prompt_container").hide();
        valid_btn_clicked = false;
        return;
    }
    valid_btn_clicked = true;
    sendContactRequest(v1url, token, articleId, contactUid, reason, function(data) {
        valid_btn_clicked = false;
        //    $(".contact-entrepreneur").unbind("click");
        //    $(".contact-entrepreneur").css("background", "#c2c2c2");
        $(".contact-entrepreneur").html("等待对方同意");
        $.jStorage.set("waitingApproved-" + articleId, "waiting");
    });
    //4.提交成功后，弹窗消失，显示成功信息
});


//点击出现联系ta的弹窗
$(".contact-entrepreneur").click(function(){
    //1.认证是否登录
    if ($.jStorage.get("localToken")) {
        if ($.jStorage.get("waitingApproved-" + _article_id)) {
            if(!IsPC()){
                window.location = "/m_contactlist?mycontacts=click";
            }else{
                window.location = "/usercenter?cate=my-contact&state=contact-todo";
            }
            
        } else if ($.jStorage.get("agreed-" + _article_id)) {
            if(!IsPC()){
                window.location = "/m_contactlist?mycontacts=click";
            }else{
                window.location = "/usercenter?cate=my-contact&state=contact-agreed";
            }
            
            
        } else if ($.jStorage.get("refused-" + _article_id)) {
            if(!IsPC()){
                window.location = "/m_contactlist?mycontacts=click";
            }else{
                window.location = "/usercenter?cate=my-contact&state=contact-refused";
            }
            
        }else{
            isValidUser(v1url, $.jStorage.get("localToken"), function(data) {           
                //判断是否同意交换，默认为不同意
                if(isAutoAgree()){
                    var contactUid = "";
                    if (_contactUid) {
                        contactUid = _contactUid;
                    }
                    sendContactRequest(v1url, $.jStorage.get("localToken"), _article_id, contactUid, "", function(data) {
                        $(".contact-entrepreneur").html("已同意");
                        $.jStorage.set("agreed-" + _article_id, "agreed");
                        var contactProfile = data.data.contact.user.profile;
                        var boardPhone = contactProfile.phone;
                        var boardMail = contactProfile.email;
                        var boardWechat = contactProfile.wechat;
                        $("#board_email").html(boardMail);
                        $("#board_wechat").html(boardWechat);
                        $("#board_phone").html(boardPhone);
                        $("#board_phone_tel").attr("href","tel:" + boardPhone);
                        $(".contact_board_container").show();
                        $("#valid_prompt_mask").show();
                        $("body").addClass("modal-open");
                        var contactLastTime = data.data.contact_last_times;
                        contactLastTime = contactLastTime.replace("\\r\\n","");
                        showWarning(contactLastTime);
                    });
                }else{
                   //不同意直接交换联系方式，需要进行验证
                    if(IsPC()){
                            $("#valid_prompt_mask").show();
                            $("body").addClass("modal-open");
                            $("#valid_prompt_container").show();
                    }else{
                        var redicHref = "/m_contactta?article_id=" + _article_id + "&contact_uid=" + _contactUid;
                        console.log(redicHref);
                       location.href = redicHref;
                    }
                    
                }

            });
        }
        
    } else {
        showWarning("您只有登录并且认证身份后，才能联系TA哦");
        $.jStorage.set("isJumpApprove", "on");
        if (IsPC()) {
            showPCLoginForm();
        } else {
            var ucValidUrl = "/m_certification";
            $.jStorage.set("localLastUrl", ucValidUrl);
            $.jStorage.set("localPhoneArticle",location.href);
            $.jStorage.set("isJumpApprove", "off");
            setTimeout(function() {
                window.location = "/login_phone";
            }, 1000);
        }

    }
    
});
